<template>
    <van-dialog v-model:show="show" title="选择要显示的学生课程" show-cancel-button @confirm="confirm()"
        :confirm-button-color="'#ee0a24'" class="md:w-1/2">
        <div class="overflow-y-scroll my-max-h py-8">
            <van-cell v-for="(name, index) in names" :key="index" :title="name.text" clickable @click="pick(name)">
                <template #right-icon>
                    <van-checkbox v-model="name.checked" @click.stop />
                </template>
                <template #label>
                    <span>{{ name.date }}</span>
                </template>
            </van-cell>
        </div>
    </van-dialog>
</template>
    
<script>
import {
    ref
} from "vue";
import {
    Dialog,
    Divider,
    Cell,
    Checkbox
} from "vant";

export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [Divider.name]: Divider,
        [Cell.name]: Cell,
        [Checkbox.name]: Checkbox
    },
    setup(props, { emit }) {
        const show = ref(false);
        const names = ref([]);
        const showDialog = function (ns) {
            show.value = true;
            names.value = ns;
        };
        const pick = function (name) {
            name.checked = !name.checked;
        };
        const confirm = function () {
            emit("done");
        };
        return {
            show,
            showDialog,
            names,
            pick,
            confirm
        };
    }
}
</script>
    
<style scoped>
.my-max-h {
    max-height: 60vh;
    scrollbar-width: none;
}

.my-max-h::-webkit-scrollbar {
    width: 0px;
}

.my-max-h::-webkit-scrollbar-track {
    display: none;
}
</style>
    