import axios from "axios";
import CheckHttp from "@/asyncs/MyHttpCheck.js";
import {
    Notify
} from "vant";
import Env from "@/logics/Envs.js";

export default async function (classId, otherClass) {
    try {
        const toSend = new FormData();
        toSend.append("classId", classId);
        if (otherClass) {
            toSend.append("otherClasses", JSON.stringify(otherClass))
        }
        const res = await axios.post(Env.apiPath + "listStudentRecord", toSend);
        if (res.data.error) {
            Notify({
                type: "danger",
                message: res.data.error,
                duration: 5000
            });
            return null;
        }
        return res.data.list;
    } catch (err) {
        CheckHttp(err);
        return null;
    }
}