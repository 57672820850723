<template>
    <div class="pt-32 flex justify-center">
        <div class="w-4/5 flex flex-col items-center gap-4">
            <div class="flex flex-row gap-4">
                <van-button type="primary" @click="showClassNames()">请求支付数据与学生课程</van-button>
                <van-button type="warning" @click="download()" v-if="rows.length">下载此数据表</van-button>
            </div>
            <div class="w-full flex flex-row justify-end">
                <van-cell-group inset class="shadow-md">
                    <van-search v-model="search" placeholder="Search..." />
                </van-cell-group>
            </div>
            <van-cell-group inset class="shadow-md">
                <vue3-datatable :rows="rows" :columns="cols" :sortable="true" :pagination="false" :pageSize="pageSize"
                    :loading="loading" ref="table" :search="search">
                </vue3-datatable>
            </van-cell-group>
        </div>
    </div>
    <name-list ref="nameList" @done="classPicked()" />
</template>

<script>
import {
    ref,
    onMounted
} from "vue";
import {
    useRoute,
    useRouter
} from "vue-router";
import {
    useStore
} from "vuex";
import {
    Button,
    Empty,
    Cell,
    CellGroup,
    Search,
    Toast
} from "vant";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import NameList from "@/components/dialog/ClassNameList.vue";
import Helpers from "@/logics/Helpers.js";
import GetSession from "@/asyncs/GetSession.js";
import ListClass from "@/asyncs/ListClassBasics.js";
import ListRecord from "@/asyncs/ListStudentRecord.js";

export default {
    components: {
        [Button.name]: Button,
        [Empty.name]: Empty,
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Search.name]: Search,
        Vue3Datatable,
        NameList
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        const route = useRoute();
        const router = useRouter();
        const nameList = ref(null);
        const table = ref(null);
        const loading = ref(false);
        const search = ref("");
        const rows = ref([]);
        const cols = ref([
            { field: "tid", title: "Transaction" },
            { field: "plan", title: "Plan Name" },
            { field: "ts", title: "Payment Time", type: "date" },
            { field: "cost", title: "Payment Amount" },
            { field: "confirmed", title: "Payment Confirmed" },
            { field: "feeShare", title: "Fee Sharing" },
            { field: "name", title: "Student Name" },
            { field: "item", title: "Classes" }
        ]);
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            });
        });

        let names = [];
        const nameMap = new Map();
        const showClassNames = async function () {
            const list = await ListClass(store.state.school.code);
            if (list) {
                names = list.map(function (c) {
                    return {
                        text: c.className,
                        cid: c.classId,
                        checked: false,
                        date: Helpers.makeDateText(c.classDates.start.year, c.classDates.start.month, c.classDates.start.day) + " --- " + Helpers.makeDateText(c.classDates.end.year, c.classDates.end.month, c.classDates.end.day)
                    };
                });
                nameList.value.showDialog(names);
            }
        };
        let size = 1;
        const pageSize = function () {
            return size;
        };
        const classPicked = async function () {
            rows.value = [];
            loading.value = true;
            const waitingToast = Toast.loading({
                message: "正在读取数据...",
                forbidClick: true,
                duration: 0
            });
            nameMap.clear();
            names.forEach(function (item) {
                if (item.checked) {
                    nameMap.set(item.cid, item.text);
                }
            });
            let pickedIds = null;
            if (nameMap.size) {
                pickedIds = [];
                for (const id of nameMap.keys()) {
                    pickedIds.push(id);
                }
            }

            const r = await ListRecord(route.params.classId, pickedIds);
            r.forEach(function (item) {
                item.tid = "..." + item.tid.slice(-5);
                item.ts = (new Date(item.ts)).toLocaleString();
                if (item.confirmed) {
                    item.confirmed = "Yes";
                } else {
                    item.confirmed = "No";
                }
                if (item.item.length) {
                    item.item.forEach((id, index, arr) => {
                        if (nameMap.has(id)) {
                            arr[index] = nameMap.get(id);
                        }
                    });
                }
                if (item.meta) {
                    item.feeShare = item.meta.feeShareTypes;
                } else {
                    item.feeShare = "N/A";
                }
            });
            rows.value = r;
            size = r.length;
            table.value.reset();
            loading.value = false;
            waitingToast.clear();
        };
        const download = function () {
            let text = "";
            const headerRow = [];
            const headerKeys = [];
            cols.value.forEach(function (item) {
                headerRow.push("\"" + item.title + "\"");
                headerKeys.push(item.field);
            });
            text += headerRow.join(",") + "\n";
            rows.value.forEach(function (item) {
                const row = [];
                headerKeys.forEach(function (key) {
                    row.push("\"" + item[key] + "\"");
                });
                text += row.join(",") + "\n";
            });
            saveText(text, "records.csv");
        };
        const saveText = function (text, fileName) {
            const blob = new Blob([text], {
                type: "text/plain;charset=utf-8"
            });
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            const url = URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(url);
            a.remove();
        };
        return {
            rows,
            cols,
            table,
            nameList,
            showClassNames,
            classPicked,
            pageSize,
            loading,
            search,
            download
        };
    }
}
</script>
